.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote {
  .text {
    width: 70%;
    word-spacing: 0.1em;
    line-height: 1.8;
    text-align: center;
    font-size: 20px;
  }
  .author {
  }
}
