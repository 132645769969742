@media only screen and (max-width: 700px) {
  .avatarPlusText {
    flex-direction: column;
  }
  .avatar {
    margin: 0 auto;
  }
  .aboutMeHeader {
    align-self: center;
  }
  .intro {
    margin: 5em auto !important;
    .introText {
      margin-top: 3em;
      width: 100% !important;
    }
  }
}

.landing {
  box-sizing: border-box;
  font-size: 18px;
  margin: 1em auto;
  max-width: 850px;
  .logo {
    width: 10%;
  }
}

.header {
  justify-content: space-between;
  border-bottom: 1px solid grey;
  align-items: center;
}

.aboutMeHeader {
}

.flex {
  display: flex;
}
.vertical {
  flex-direction: column;
  align-self: center;
}
.avatarPlusText {
  justify-content: space-between;
}
.intro {
  margin: 10em 0;

  .introText {
    width: 55%;
    text-align: justify;
  }

  .aboutMeText {
    word-spacing: 0.2em;
    line-height: 1.8;
    margin-top: 0px;
  }
  .avatar {
    flex-direction: column;
  }
  .connectWithMe {
    margin-top: 1em;
    justify-content: center;
  }
  .instagram {
    margin-left: 0.5em;
  }
  .github {
    margin-left: 0.5em;
    color: black;
  }
}

.inspirationalQuotes {
  background-color: lightcyan;
  padding: 3em;
  margin: 5em 0;
  .inspiringHeading {
    width: fit-content;
    margin: 0 auto;
    letter-spacing: 1.5px;
  }
  border-radius: 1em;
}

.textAlign {
  text-align: center;
}

.contactFormHeading {
  width: fit-content;
  margin: 0 auto;
  letter-spacing: 1px;
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing;
  animation-duration: 2.5s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.contactForm {
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  .subject {
    margin-top: 1em;
  }
  .message {
    margin-top: 1em;
  }
  .submitBtn {
    width: fit-content;
    margin: 0 auto;
    margin-top: 1em !important;
  }
}
